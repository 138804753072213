html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

/* canvas {
  width: 100%;
  height: 700px;
} */

.construktor {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 0; /* Опционально, добавляет промежуток между колонками */
  height: 100vh; /* Задайте высоту по вашему усмотрению */
}

.controls-content {
  overflow-y: auto;
  max-height: 100vh;
  padding: 25px;
}

.wraper-plitka {
  padding: 20px 20px 20px 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 30px;
  position: relative;
  display: block;
}

.home-button-logo {
  margin-bottom: 10px;
  display: block;
}

.home-button {
  color: #000000;
  text-decoration: none;
}

.plitka-title {
  font-weight: 700;
  font-size: 22px;
  position: absolute;
  margin-top: -34px;
  margin-left: -10px;
  background-color: #fff;
  padding: 0 10px;
}

.plitka-subtitle {
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
}

.plitka-btn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  height: 30px;
}

.plitka-btn-color {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.plitka-btn-color-collection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 5px 0;
}

.plitka-parameters {
  padding-top: 15px;
}

.plitka-parameters label {
  padding-left: 15px;
}

.plitka-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  height: 30px;
}

.plitka-input input {
  /* max-width: 123px; */
}

.plitka-input button {
  width: 100%;
}

.plitka-parameters select {
  width: 100%;
  /* max-width: 123px; */
  height: 30px;
  margin-bottom: 15px;
}

.plitka-phone {
  display: inline;
  width: 100%;
  height: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-left: 15px;
}

.plitka-btn-send {
  width: 100%;
  height: 30px;
  background: #B28A6A;
  border: 1px solid #B28A6A;
  color: #fff;
  border-radius: 2px;
}

.plitka-btn-send:hover {
  background: #815d3f;
  border: 1px solid #815d3f;
}

button {
  cursor: pointer;
}
